import React from 'react';
import Container from 'react-bootstrap/Container';
import { FaMedal } from "react-icons/fa6";
import Table from 'react-bootstrap/Table';
import getContributors from '../services/UserBackEnd.js'
import parse from 'html-react-parser';

function Contributor() {

    const [stopCall, setStopCall] = React.useState(false);
    const [contributors, setContributors] = React.useState(false);

    if (stopCall == false) {
        getContributors().then((value) => {
            setStopCall(true)
            var i = 0;
            var arrayResult = "";
            while (i < value.length) {
                console.log(value[i].toLocaleString())
                var splitteddata = value[i].toLocaleString().split(",");
                var rank = i + 1
                arrayResult = arrayResult + "<tr><td>#" + rank + "</td><td>" + splitteddata[0]+ "</td><td>" + splitteddata[1]+ "</td></tr>"


                i = i + 1;
            }
            setContributors(arrayResult)
        })
    }

	return (
			<Container>
 <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Pseudo</th>
          <th>Total tests</th>
        </tr>
      </thead>
      <tbody>
      {parse("" + contributors)}
      </tbody>
    </Table>
			</Container>
		);
	
}

export default Contributor;