
import axios from "axios";

export default async function getUserByEmail(email){

	var customConfig = {
		headers: {
		'Content-Type': 'application/json'
		}
	};
	let res =  await axios.get('api/getUserByEmail/' + email,null,customConfig)
	.then((response) => {
		return response.data;
	})
	.catch((error) => {
		console.log(error);
	})
    return res;
}