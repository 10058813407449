import axios from "axios";

export default async function addUser(id,name,given_name,family_name,email){

	var	customConfigJSON = { headers: { "Content-type": "application/json",}};
	var newUser = {"username":given_name,
					"name":name,
					"lastName":family_name,
					"email":email,
					"pseudo":"TODO"
	}
	return axios.post("api/addUser",newUser ,customConfigJSON)
		.then((response) => {
			console.log(response)
			localStorage.setItem("userID", response.data.id);
		})
		.catch((error) => {
			console.log(error);
		})

}