import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import enionax from '../assets/about/enionax.jpg'

import { BsDiscord } from 'react-icons/bs';

class About extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Container>
				<br />
				<h1>About</h1>
					<h3>Informations</h3>
					<p>Mac Game Toolkit is just an information webiste. <br />If you have problem or question come to our discord <BsDiscord size={20}/> to see with us : <a href="https://discord.gg/rD6nduX6af" target="_blank">
						Discord
					</a><br />
					MGT is on affiliation program of CrossOver, download link : <a href="https://www.codeweavers.com/store?ad=1069">here</a></p>
					<hr/>
					<h3>Creators</h3>
					<p>Antoine Souchet <a href="https://fr.linkedin.com/in/antoine-souchet-70a32b5a" target="_blank">Linkedin</a><br />
					</p>
					<hr/>
					<h3>Special thanks</h3>
						<p>Thanks to MacProTips ! Youtube channel : <a href="https://www.youtube.com/@macprotips" target="_blank">here</a></p>
					<hr/>
					<h3>
						Other project
					</h3>
					<a href="https://store.steampowered.com/app/1725470/Enionax/" target="_blank"><Image src={enionax} thumbnail/></a>
					<p>We have created a video game too : Enionax.<br />You can found it on steam <a href="https://store.steampowered.com/app/1725470/Enionax/" target="_blank">here</a> with ofc a Mac Native version :)</p>
			</Container>

		);
	}
}

export default About;