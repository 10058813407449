import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import errorImage from '../assets/404/404.jpeg'

class NotFound extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Container>
			
			<center>
				<h1>Page not found</h1>
				<Image src={errorImage} style={{'width':'700px'}}thumbnail/> <br />
				<i>powered by DALL·E 3</i>
			</center>
			</Container>

		);
	}
}

export default NotFound;