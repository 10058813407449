import React from "react";
import { useEffect, useState } from "react";
import { MdDarkMode } from "react-icons/md";
import { CiLight } from "react-icons/ci";
import { FaHeart } from "react-icons/fa";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import logo from '../assets/brand/logo.jpeg';
import { FaXTwitter } from "react-icons/fa6";
import Image from 'react-bootstrap/Image';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useNavigate } from 'react-router-dom'
import axios from "axios";

const MyNavbar = () => {

	const navigate = useNavigate();
	const [stopCall, setStopCall] = React.useState(false);

	const [games, setGames] = useState(false);
	const [theme, setTheme] = useState(<CiLight />);

	function switchTheme() {
		if (document.documentElement.getAttribute('data-bs-theme') == 'dark') {
			document.documentElement.setAttribute('data-bs-theme','light')
			setTheme(<MdDarkMode />)
		}
		else {
			document.documentElement.setAttribute('data-bs-theme','dark')
			setTheme(<CiLight />)
		}
	}

		if (stopCall == false) {
			axios.get('/api/getAllGames',{ headers: { "Content-type": "application/json"}})
			.then((response) => {
				console.log(response)
				setGames(response.data)
				setStopCall(true)
			})
		}
	

	
	  const handleOnSearch = (string, results) => {
		// onSearch will have as the first callback parameter
		// the string searched and for the second the results.
		console.log(string, results)
	  }
	
	  const handleOnHover = (result) => {
		// the item hovered
		console.log(result)
	  }
	
	  const handleOnSelect = (item) => {
		// the item selected
		console.log(item)
		navigate('/gamedetail', {state:{ data: item }});
	  }
	
	  const handleOnFocus = () => {
		console.log('Focused')
	  }
	
	  const formatResult = (item) => {
		return (
		  <>
			<span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
		  </>
		)
	  }
	  
	function logout() {
		localStorage.removeItem('userID')
		localStorage.removeItem('authenticated')
		localStorage.removeItem("FullName");
		localStorage.removeItem("Email");
		localStorage.removeItem("createPseudo")
		localStorage.removeItem("pseudo")
		navigate('/Home', { replace: true });
	}

	return (
		<Navbar expand="lg"  >
			<Container fluid>
		<Navbar.Brand href="#Home">
			<Image
			  alt=""
			  src={logo}
			  thumbnail
			  width="30"
			  height="30"
			  className="d-inline-block align-top"
			/>{' '}MGT</Navbar.Brand>
		<Navbar.Toggle aria-controls="basic-navbar-nav" />
		<Navbar.Collapse id="basic-navbar-nav">
			<Nav className="me-auto">
			<Nav.Link href="#News">News</Nav.Link>
			<Nav.Link href="#AllGames">Games list</Nav.Link>
			<Nav.Link  href="#AddGame">
					Add a game
				</Nav.Link >
			<NavDropdown title="Specific list" id="basic-nav-dropdown">
				<NavDropdown.Item href="#OKGames">
					Only Games working
				</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item href="#AskedGames">
					Games waiting for test
				</NavDropdown.Item>
			</NavDropdown>
			<NavDropdown title="Community" id="basic-nav-dropdown">
				<NavDropdown.Item href="#ASK">
				Ask for a game
				</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item href="#Contributor">
				Contributors
				</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item href="#X">
				<FaXTwitter />
				</NavDropdown.Item>
			</NavDropdown>
			<NavDropdown title="Tutorials" id="basic-nav-dropdown">
				<NavDropdown.Item href='#Whisky'>Install Whisky</NavDropdown.Item>
				<NavDropdown.Item href="#CMD">Usefull command</NavDropdown.Item>
				<NavDropdown.Item href="#cxPatch">CX Patcher</NavDropdown.Item>
			</NavDropdown>
			<Nav.Link href="#About">About</Nav.Link>
			<Nav.Link  href="https://www.codeweavers.com/store?ad=1069" target="_blank">MGT <FaHeart style={{"color":"red"}}/> CrossOver</Nav.Link>
			<Form className="d-flex">
			<div style={{ width: 300 }}>
			<ReactSearchAutocomplete
				items={games}
				onSearch={handleOnSearch}
				onHover={handleOnHover}
				onSelect={handleOnSelect}
				onFocus={handleOnFocus}
				autoFocus
				placeholder="Search game"
				formatResult={formatResult}
				styling={
				{
					height:"28px",
					borderRadius:"0px",
					margin:"5px"
				}}
			 />
		</div>
			</Form>
			
			</Nav>
			<Button variant="link" onClick={() => switchTheme()}>{theme}</Button>
			<Button variant="primary" href="#Login" style={!localStorage.getItem('authenticated') ? {} : { display: 'none', marginLeft :'4px' }}>Login</Button>
			<div style={localStorage.getItem('authenticated') ? {} : { display: 'none' }}>
				<Button variant="danger" onClick={logout} >Logout{' '}{' '}</Button>
			</div>
		</Navbar.Collapse>
		</Container>
	</Navbar>
	);
};
 
export default MyNavbar;