import React from 'react';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import { GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import TwitterLogin from 'react-twitter-auth/lib/react-twitter-auth-component.js';
import LoginBtn from '../components/LoginBtn.js'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from "axios";


const handleSubmit = (event) => {
	event.preventDefault();
	console.log("refresh prevented");
	const pseudo = event.target.pseudo.value;

	axios.post("/api/updateUser?id=" + localStorage.getItem("userID") + "&pseudo=" +pseudo, null ,null)
		.then((response) => {
			localStorage.setItem("authenticated", true);
			localStorage.setItem("pseudo", pseudo);
			window.location.reload(false);
		})
		.catch((error) => {
			return (
			<Container>
				<Alert  variant="danger">Can't create pseudo + {error}</Alert>
			</Container>
			)
		})
}

function Login() {

	//AAAAAAAAAAAAAAAAAAAAAOLWqwEAAAAA227bS6JW6c79eqUj79Y5714V5jA%3DGi5POzZXvVzxNEHzkDhCu7IP1vvM8A8D0ZTj0kGTc8GDHeTJxv

	//B0pYjGg7YgzqbNwGS_UmKVBI-62K2IO1IX24ixxH4V3gfOHvY1

	const onFailed = (err, data) => {
		console.log(err, data);
	  };

	const onSuccess = (err, data) => {
		console.log(err, data);
	  };
		if (localStorage.getItem("authenticated") == "true") {
			return (
				<Container>
					<Alert  variant="success">Login success !<br /><br ></br>Welcome {localStorage.getItem("pseudo")}</Alert>
				</Container> )
		} else if(localStorage.getItem("createPseudo") == "true") {
			return (<Container>
					<Form  onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Choose a username for Mac Game Toolkit :</Form.Label>
						<Form.Control type="text" placeholder="username" id='pseudo' required />
					</Form.Group>
					<Button type="submit">Save</Button>
					</Form>
				</Container>
			)
		} else {
			return (
				<Container>
				<GoogleOAuthProvider clientId="1064650654311-o1p62r6vjdfdsuvkn2i8cdn1b15dlp8e.apps.googleusercontent.com">
					<LoginBtn />
				</GoogleOAuthProvider><br />
			</Container>
			
			)
		}

}

export default Login;