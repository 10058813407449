import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Discord from '../services/Discord.js';
import axios from "axios";
import { useState } from 'react';

function AddGame()  {


	const [validated, setValidated] = useState(false);
	const [isAno, setIsAno] = useState(false);
	const [gameAdd, setGameAdd] = useState(false);
	const [gameError, setGameError] = useState(false);
	const [finalGameName, setFinalGameName] = useState(false);

	const onAnonymousMethodChange = (event) => {
		setIsAno(event.target.checked)
	}
	const handleSubmit = (event) => {
		event.preventDefault();
		console.log("refresh prevented");
		const form = event.currentTarget;

		if (form.checkValidity() === false) {
		  event.preventDefault();
		  event.stopPropagation();
		  setValidated(true);
		  const anonymous = event.target.anonymous.value;
		  return false;
		}
		var dateImage = "";
		if (localStorage.getItem("FullName") == null) {
			Alert('Your session is expired')
			window.location.reload(false);
		} else {
			if (event.target.image.files[0] !== undefined) {
				dateImage = new Date().toLocaleString() + '' + '.png';
				dateImage = dateImage.replaceAll("/","")
				dateImage =  dateImage.replaceAll(":","")
				dateImage =  dateImage.replaceAll(" ","")
			}
			const gameName = event.target.name.value;
			const gameDescription = event.target.desc.value;
			const gameType = event.target.type.value;
			const chipset = event.target.chipset.value;
			const ram = event.target.ram.value;
			const store = event.target.store.value;
			const settings = event.target.settings.value;
			const framerate = event.target.framerate.value;
			const results = event.target.results.value;
			const youtube = event.target.youtube.value;
			const res = event.target.res.value;
			const anonymous = isAno;
			const image = dateImage;
			const controller = event.target.controller.value;
			var user = 0;

			if(anonymous == false) {
				user = localStorage.getItem("userID");
			}
			const userName = user;

			var textResult = '';
			var textResultsWeb = '';
			if (results == 1) {
				textResult = 'Works perfect :wink:'
				textResultsWeb = "OK";
			} else if (results == 2) {
				textResult = 'Works :punch:'
				textResultsWeb = "OKBUT";
			} else if (results == 3) {
				textResult = 'Works but unplayable :scream:'
				textResultsWeb = "OKBAD"
			} else if (results == 4) {
				textResult = 'Doesn\'t works :cry:'
				textResultsWeb = "KO"
			}


			setFinalGameName(gameName);
			const userGame = JSON.stringify({ 
				"name": gameName,
				"ram": ram,
				"youtube": youtube,
				"chipset": chipset,
				"method": gameType,
				"mydate": new Date().toLocaleString() + '',
				"result": textResultsWeb,
				"store": store,
				"image": "uploads/" + dateImage,
				"resolution": res,
				"controller":controller,
				"framerate":framerate,
				"description":gameDescription,
				"settings":settings,
				"iduser":user
			});

			var	customConfigJSON = { headers: { "Content-type": "application/json",}};
			var	customConfigForm = { headers: { "Content-type": "multipart/form-data",}};
			const formData = new FormData();
 
			let newDate = new Date()
			let date = newDate.getDate();
			formData.append("file", event.target.image.files[0]);
			axios.post("api/addGame",userGame ,customConfigJSON)
				.then((response) => {
					try {
						axios.post("/api/addGameImage",{
							file: event.target.image.files[0],
							imageName: dateImage,
						} ,customConfigForm)
						.then((response) => {

						})
						.catch((error) => { console.log(error) });
					} catch(error) {
						console.log(error)
					}
				setGameAdd(true)
			})
			.catch((error) => { setGameError(true); return false; });;

			var discordImage = image;

			if (discordImage == "" || discordImage == null || discordImage == undefined) {
				discordImage = "https://macgametoolkit.com/uploads/logo.jpeg";
			} else {
				discordImage = "https://macgametoolkit.com/uploads/" + image;
			}
			var discordUser = localStorage.getItem("pseudo");

			if (user == 0) {
				discordUser = "Anonymous"
			}
			var DisordData = {"title": gameName,
				"description": "Results : " + textResult + " by : " + discordUser ,
				"image": {
					"url": discordImage
					},
				"footer": {
					"text": new Date().toLocaleString() + ''
				}
			}
			if (gameError == false) { {Discord(DisordData)} }

		}


		};

		return (
			<Container >
				<div style={!localStorage.getItem('authenticated') ? {} : { display: 'none' }}>
					<Alert  variant="danger">An account is required to add a game !</Alert>
				</div>
				<br />
				{gameAdd == false && (<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<div style={localStorage.getItem('authenticated') ? {} : { display: 'none' }}>
					<h1>Add a game into <i>Mac Game Toolkit</i></h1>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Game name</Form.Label>
						<Form.Control type="text" placeholder="Counter Strike 2" id='name' required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
						<Form.Label>Game description</Form.Label>
					<Form.Control as="textarea" rows={4} placeholder="Quick game description" id='desc' required/>
					</Form.Group>
					<Form.Label>Method</Form.Label>
					<Form.Select aria-label="Default select example" id='type'>
						<option value="GPT">Standalone GPT</option>
						<option value="Whisky">Whisky</option>
						<option value="Crossover">Crossover</option>
						<option value="Native">Native</option>
					</Form.Select>
					<Form.Label>Chipset</Form.Label>
					<Form.Select aria-label="Default select example" id='chipset'>
						<option value="M1">M1</option>
						<option value="M1 Pro">M1 Pro</option>
						<option value="M1 Max">M1 Max</option>
						<option value="M1 Ultra">M1 Ultra</option>
						<option value="M2">M2</option>
						<option value="M2 Pro">M2 Pro</option>
						<option value="M2 Max">M2 Max</option>
						<option value="M2 Ultra">M2 Ultra</option>
						<option value="M3">M3</option>
						<option value="M3 Pro">M3 Pro</option>
						<option value="M3 Max">M3 Max</option>
					</Form.Select>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>RAM</Form.Label>
						<Form.Select aria-label="Default select example" id='ram'>
						<option value="8 GB">8 GB</option>
						<option value="16 GB">16 GB</option>
						<option value="18 GB">18 GB</option>
						<option value="32 GB">32 GB</option>
						<option value="36 GB">36 GB</option>
						<option value="64 GB">64 GB</option>
					</Form.Select>
					</Form.Group>
					<Form.Label>Store</Form.Label>
					<Form.Select aria-label="Default select example" id='store'>
						<option value="Steam">Steam</option>
						<option value="Epic">Epic games</option>
						<option value="Battle">Battle Net</option>
						<option value="Battle">App Store</option>
						<option value="Other">Other</option>
					</Form.Select>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Resolution</Form.Label>
						<Form.Select aria-label="Default select example" id='res'>
							<option value="720p">720p</option>
							<option value="1080p">1080p</option>
							<option value="1440p">1440p</option>
							<option value="2K">2K</option>
							<option value="4K +">4K +</option>
						</Form.Select>
					</Form.Group>
					<Form.Label>Controllers</Form.Label>
					<Form.Select aria-label="Default select example" id='controller'>
						<option value="Keyboard + moose">Keyboard + moose</option>
						<option value="XBOX Controller">XBOX Controller</option>
						<option value="Ps4 / 5 Controller">Ps4 / 5 Controller</option>
						<option value="Other">Other</option>
					</Form.Select>
					<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
						<Form.Label>Specifics Configuration</Form.Label>
					<Form.Control as="textarea" rows={2} placeholder="Need to change DLL ? Update parameters ? Esync ? " id='settings' />
					</Form.Group>
					<Form.Label>Framerate</Form.Label>
					<Form.Select aria-label="Default select example" id='framerate'>
						<option value="0 ips">0 ips</option>
						<option value="0 to 30ips">0 to 30ips</option>
						<option value="30 to 45ips">30 to 45ips</option>
						<option value="45 to 60ips" selected>45 to 60ips</option>
						<option value="60ips +">60ips +</option>
					</Form.Select>
					<Form.Label>Works ?</Form.Label>
					<Form.Select aria-label="Default select example" id='results'>
						<option value="1">Yes perfectly</option>
						<option value="2">Yes but glitch and error</option>
						<option value="3">Yes but unplayable</option>
						<option value="4">No</option>
					</Form.Select>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Youtube</Form.Label>
						<Form.Control type="text" placeholder="YouTube links to the game with Apple GPT" id='youtube' />
					</Form.Group>
					<Form.Group controlId="formFile" className="mb-3">
						<Form.Label>Game image</Form.Label>
						<Form.Control type="file" id='image' accept="image/png, image/jpg, image/jpeg" />
					</Form.Group>
					<Form.Check 
						type='checkbox'
						id='anonymous'
						label='Anonymous'

						onChange={onAnonymousMethodChange}
					/>
					<br ></br>
					<Button type="submit" style={localStorage.getItem('authenticated') ? {} : { display: 'none' }}>Submit</Button>
					</div><br /></Form>)}
					{gameAdd == true && (
						<Alert  variant="success">{finalGameName} has been added ! <br /><br ></br>Thank you for contributing :)</Alert>
					)}
					{gameError == true && (
						<Alert  variant="danger">Error to add this game (Database error)</Alert>
					)}
				
				
			</Container>

		);

		
	}

export default AddGame;