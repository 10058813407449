import React from 'react';
import Container from 'react-bootstrap/Container';

class CxPatch extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Container>
				<br />
				<h1>Get better crossover with CXPatcher</h1>
					<h3>Patch crossover</h3>
					<p>Very usefull project to get better performance and compability is CXPatcher, you can download it <a href="https://github.com/italomandara/CXPatcher" target='_blank'>here</a><br /><br />
						You need to read readme.md before installing it !<br /><br />
					Release page <a href="https://github.com/italomandara/CXPatcher/releases" target='_blank'>here</a>
					</p>
			</Container>

		);
	}
}

export default CxPatch;