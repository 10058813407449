import React from 'react'
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Link, useParams, useNavigate } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import AddUser from '../services/AddUser.js'
import getUserByEmail from '../services/GetUserByEmail.js'

const LoginBtn = () => {
		const [authenticated, setauthenticated] = useState(null);
		const navigate = useNavigate();
		useEffect(() => {
				const loggedInUser = localStorage.getItem("authenticated");
				if (loggedInUser) {
					setauthenticated(loggedInUser);
				}
			}, []);

	return (
		<div><center>
				<GoogleLogin
					onSuccess={credentialResponse => {

						localStorage.removeItem('authenticated');
						localStorage.removeItem("FullName");
						localStorage.removeItem("Email");
						localStorage.removeItem("createPseudo");
						localStorage.removeItem("pseudo");
						localStorage.removeItem("jti");

						const responsePayload = jwtDecode(credentialResponse.credential);
						getUserByEmail(responsePayload.email).then((value) => { 
							if (value.length == 0 ) {
								console.log("create user into DB")
								AddUser(responsePayload.sub,responsePayload.name,responsePayload.given_name,responsePayload.family_name,responsePayload.email);
								localStorage.setItem("createPseudo", true);
								window.location.reload(false);
							} else if (value[0].pseudo == "TODO"){
								localStorage.setItem("userID", value[0].id);
								localStorage.setItem("createPseudo", true);
								localStorage.setItem("FullName", responsePayload.name);
								localStorage.setItem("Email", responsePayload.email);
								localStorage.setItem("jti", responsePayload.jti)
								window.location.reload(false);
							}
							else {
								localStorage.setItem("authenticated", true);
								localStorage.setItem("FullName", responsePayload.name);
								localStorage.setItem("Email", responsePayload.email);
								localStorage.setItem("pseudo", value[0].pseudo);
								localStorage.setItem("jti", responsePayload.jti)
								window.location.reload(false);
							}
							//window.location.reload(false);
						})


					}}
					onError={() => {
						console.log('Login Failed');
					}}
					useOneTap
				/>
				</center>
		</div>
	)
}

export default LoginBtn