import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import Alert from 'react-bootstrap/Alert';
import { useState } from 'react';

function ASK()  {

	const [validated, setValidated] = useState(false);
	const [gameAsk, setGameAsk] = useState(false);
	const [nameGame, setNameGame] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();
		console.log("refresh prevented");

		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			return false;
		}
		setValidated(true);
		const nameGame = event.target.name.value
		const askGame = JSON.stringify({ 
			"name": event.target.name.value,
			"description": event.target.desc.value,
			"method": event.target.type.value
		});

		var	customConfigJSON = { headers: { "Content-type": "application/json",}};
		axios.post("/api/askGame",askGame ,customConfigJSON)
				.then((response) => {
					console.log('Done')
					setGameAsk(true)
					setNameGame(event.target.name.value)
				});
	  };

		return (
			<Container>
				<br />
				<h1>Ask a test for a game</h1>

				{gameAsk == false && (<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Game name</Form.Label>
						<Form.Control type="text" placeholder="Counter Strike 2" id='name' required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
						<Form.Label>Test description</Form.Label>
					<Form.Control as="textarea" rows={4} placeholder="Test on steam with an M1 or M2 ?" id='desc' required/>
					</Form.Group>
					<Form.Label>Method</Form.Label>
					<Form.Select aria-label="Default select example" id='type'>
						<option value="GPT">Standalone GPT</option>
						<option value="Whisky">Whisky</option>
						<option value="Crossover">Crossover</option>
					</Form.Select><br ></br>
					<Button type="submit" >Submit</Button>
				</Form>)}
				{gameAsk == true && (
						<Alert  variant="success">{nameGame} has been asked ! <br /><br ></br>Thank you for contributing :)</Alert>
				)}

			</Container>

		);

		
	}

export default ASK;