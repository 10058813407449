import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import terminal from '../assets/tuto/terminal.png'
import terminal2 from '../assets/tuto/terminal2.png'

class Cmd extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Container>
				<br />
				<h1>Usefull commands/tips for Apple GPTK</h1>
					<h3>Kill all runing bootles</h3>
					<p>Launch a terminal and set this command : <br /><br />
					<samp>killall -9 wineserver && killall -9 wine64-preloader</samp><br />
					Press <kbd>ctrl + c</kbd> if command does not work<br /><br />
					<Image src={terminal} fluid></Image></p>
					<h3>Error on requires Mono, .Net</h3>
					<p>Download required package (on Microsoft website) and launch this command : </p>
					<samp>WINEPREFIX=~/my-game-prefix $(brew --prefix game-porting-toolkit)/bin/wine64 installer.exe</samp><br />
					Replace <i>installer.exe</i> with .exe you have download.<br /><br />
					<Image src={terminal2} fluid></Image>
					<h3>No more space to install game</h3>
					<p>Bottle needs to be restart after uninstall a game. So after a unistall juste kill bottle and launch it again.</p>
			</Container>

		);
	}
}

export default Cmd;