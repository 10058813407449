import React from 'react';
import Container from 'react-bootstrap/Container';
import {useLocation} from 'react-router-dom';
import { FcOk, FcHighPriority, FcEngineering, FcEmptyTrash} from "react-icons/fc";
import errorImage from '../assets/404/unplug.jpeg'
import Image from 'react-bootstrap/Image';

const Gamedetail = ({route,navigate}) => {
    const location = useLocation();
    const game = location.state.data;

	function getUrl(youtubeUrl) {
		try {
			var splitUrl = youtubeUrl.split('?')
			var secondSplit = splitUrl[1].split('&')
			youtubeUrl = 'https://www.youtube.com/embed/' + secondSplit[0].replace('v=','') + '?autoplay=0';
			return youtubeUrl;
		} catch(error) {
			return null
		}
	}

    function resultsFormatter(cell) {
		if (cell == "OK") {
		  return (
			<span>
			  <strong style={ { color: 'green' } }>Game runs perfectly <FcOk /></strong>
			</span>
		  )
		} else if (cell == "OKBUT") {
			return (
			  <span>
				<strong style={ { color: 'orange' } }>Runs with problem <FcEngineering></FcEngineering></strong>
			  </span>
			)
		} else if (cell == "OKBAD") {
			return (
			  <span>
				<strong style={ { color: 'purple' } }>Runs but unplayable <FcEmptyTrash></FcEmptyTrash></strong>
			  </span>
			)
		}
		else {
			return (
			  <span>
				<strong style={ { color: 'red' } }><FcHighPriority></FcHighPriority> Not working </strong>
			  </span>
			);
	  }
	}
    
    function imgFormatter (cell, row) {
		if (cell === "") {
			return (
				<Image src={errorImage} style={{"width":"250px"}} thumbnail/>
			);
		} else if (cell == "uploads/") {
		return (
			<Image src={errorImage} style={{"width":"250px"}} thumbnail/>
		); 
		} else if (cell == null)
			return (
				<Image src={errorImage} style={{"width":"250px"}} thumbnail/>
			);
		else {
			return (
				<Image src={cell} style={{"width":"250px"}} thumbnail />
			);
		}

	}

     return (
       <Container>
         <h1><center>{game.name}</center></h1>
         <h3>Results : {resultsFormatter(game.result)}</h3>
         <p><i>{game.description}</i></p>
         <p>Test date : {game.mydate}</p>
         <p>Method test : {game.method}</p>
         <p>Framerate (ips) : {game.framerate} on resolution : {game.resolution} </p>
         {game.youtube != "" &&  (<div class="video-container"><br /><iframe width="420" height="315"
								src={getUrl(game.youtube)}>	
							</iframe> </div>)}
         {imgFormatter(game.image)}
       </Container>
     )
   }
   
   export default Gamedetail