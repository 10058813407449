import axios from "axios";

export default async function getAllGame(){

	var customConfig = {
		headers: {
		'Content-Type': 'application/json'
		}
	};
	// Using Axios - ensure you first install the package
	let res =  await axios.get('api/getAllGames',null,customConfig)
	.then((response) => {
		return response.data;
	})
	.catch((error) => {
		console.log(error);
	})
	return res;
}