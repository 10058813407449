import React from 'react';
import Container from 'react-bootstrap/Container';
import axios from "axios";
import parse from 'html-react-parser';

function News() {

	const [stopCall, setStopCall] = React.useState(false);
	const [news, setNews] = React.useState(false);

	if (stopCall == false) {
		axios.get('/api/getAllNews',null)
		.then((response) => {
			console.log(response)
			var i = 0;
			var newsList = "";
			while (i < response.data.length) {
				newsList = newsList + "<div><Image src='"+ response.data[i].image +"' style='width:50px;' thumbnail /><h2>" + response.data[i].title + "</h2><i>" + response.data[i].createDate + "</i><p>" + response.data[i].content + " <br /><br /><i>" + response.data[i].author + "</i></p></div><hr />"
				i = i + 1;
			}
			setNews(newsList)
			setStopCall(true)
		})
		.catch((error) => {
			console.log(error);
		})
	}


		return (
			<Container>
				{parse("" + news)}
			</Container>
		);
}

export default News;