import axios from "axios";

export default function sendDiscord(data){

	const content = JSON.stringify({
		"username":"Mac Game Toolkit Website",
		"content":"A new game has been reported !",
		"embeds" : [ data ]
	})
	var customConfig = {
		headers: {
		'Content-Type': 'application/json'
		}
	};
	// Using Axios - ensure you first install the package
	axios.post('https://discord.com/api/webhooks/1169587709766029322/tk4x2bsRVTKroLPhoA20wV7V08xkHIuD55NohFREtKeai4WE5zx7L5eVTfPHtypFCKtE',content,customConfig)
	.then((response) => {
		console.log(response.data);
	})
	.catch((error) => {
		console.log(error);
	})
	}