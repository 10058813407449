import React from 'react';
import Container from 'react-bootstrap/Container';
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom'

function News() {

	const [stopCall, setStopCall] = React.useState(false);
	const [askedGames, setAskedGames] = React.useState(false);
	const navigate = useNavigate();

	if (stopCall == false) {
		axios.get('/api/getAskedGame',null)
		.then((response) => {
			console.log(response)
			setAskedGames(response.data)
			setStopCall(true)
		})
		.catch((error) => {
			console.log(error);
		})
	}

	function goAddGame(cell, row) {
		console.log(cell,row)
		navigate('/AddGame', {state:{ data: cell }});
	}

	function resultFormatter (cell ,row) {
		return (
		<span>
			<Button onClick={() => goAddGame(row,cell)}>Sending results</Button>
		  </span>
		);
	}

	const columns = [{
		dataField: 'name',
		text: 'Name',
		filter: textFilter({
			placeholder: 'Search game name',  // custom the input placeholder
			className: 'my-custom-text-filter', // custom classname on input
			caseSensitive: false, // default is false, and true will only work when comparator is LIKE
			delay: 1000, // how long will trigger filtering after user typing, default is 500 ms
			id: 'id', // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
		  }),
		//formatter: titleFormatter,
		sort: true
	},
	{
		dataField: 'description',
		text: 'Description',
		align:'center'
		//formatter: resultsFormatter
	},
	{
		dataField: 'method',
		text: 'Method',
		align:'center'
		//formatter: detailsFormatter
	},{
		dataField: 'id',
		text: 'Send results',
		align:'center',
		formatter: resultFormatter
	}];


		return (
			<Container>
				<BootstrapTable keyField='id' data={ askedGames } columns={ columns } filter={ filterFactory() } pagination={ paginationFactory() } />
			</Container>
		);
}

export default News;