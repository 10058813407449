import React from "react";

import Container from "react-bootstrap/esm/Container.js";
import Image from 'react-bootstrap/Image';
import { FcOk, FcHighPriority, FcEngineering, FcEmptyTrash} from "react-icons/fc";
import { BsDiscord } from 'react-icons/bs';
import logo from '../assets/brand/logo.jpeg'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import errorImage from '../assets/404/unplug.jpeg'
import { useState } from 'react';

const Home = () => {

	var test; 
	var	customConfigJSON = { headers: { "Content-type": "application/json",}};
	const [stopCall, setStopCall] = React.useState(false);
	const [validated, setValidated] = useState(false);
	const [randomGame, setRandomGame] = useState(false);
	const [image, setImage] = useState(false);
	
	function resultsFormatter(value) {
		if (value == "OK") {
		  return (
			<span>
			  <FcOk />&nbsp;<strong style={ { color: 'green' } }>Game runs perfectly </strong>
			</span>
		  )
		} else if (value == "OKBUT") {
			return (
			  <span>
				<FcEngineering />&nbsp;<strong style={ { color: 'orange' } }>Runs with problem </strong>
			  </span>
			)
		} else if (value == "OKBAD") {
			return (
			  <span>
				<FcEmptyTrash />&nbsp;<strong style={ { color: 'purple' } }>Runs but unplayable </strong>
			  </span>
			)
		}
		else {
			return (
			  <span>
				<FcHighPriority />&nbsp;<strong style={ { color: 'red' } }>Not working </strong>
			  </span>
			);
	  }
	}

	if (stopCall == false) {
		axios.get("/api/getCountGames",null ,customConfigJSON)
		.then((response) => {
			setValidated(response.data);
		});

		axios.get("/api/getRandomGame",null ,customConfigJSON)
		.then((response) => {
			setRandomGame(response.data);
			if (response.data.image == undefined) {
				setImage(false)
			} 
			else if (response.data.image == "uploads/") {
				setImage(false)
			}
			else {
				setImage(true)
			}
			setStopCall(true)
		});
	}

	return (
		<Container>
			<h1>Mac Game Toolkit</h1>
			<p>A dynamic list of games running (or not) with <a href='https://developer.apple.com/videos/play/wwdc2023/10123/' target="_blank" >Apple GPTK</a>.</p>
			<p>Best way to play windows game on mac is Crossover : <a href="https://www.codeweavers.com/store?ad=1069" target="_blank">Get CrossOver</a></p>
			Currently : <b>{validated}</b> games has been tested ! Come to our <a href="https://discord.gg/rD6nduX6af" target="_blank">Discord</a> for more informations ! <a href="https://discord.gg/rD6nduX6af" target="_blank">
						<BsDiscord size={30}/></a><br /><br /><center>
			Random game test results : 
			<Card style={{ width: '18rem' }}>
			{image == false && (<Card.Img variant="top" src={errorImage} />)}
			{image && (<Card.Img variant="top" src={randomGame.image} />)}
				<Card.Body>
				<Card.Title>{randomGame.name}</Card.Title>
				<Card.Text>
					{resultsFormatter(randomGame.result)} on {randomGame.chipset}<br /><i>{randomGame.description}</i>
				</Card.Text>
				<Button variant="primary" onClick={() => setStopCall(false)}>Random another game</Button>
				</Card.Body>
			</Card>
			<br />
				<Image src={logo} style={{'width':'200px'}}thumbnail/> <br />
			</center>
		</Container>
	);
};
 
export default Home;