
import './App.css';

import Home from './pages/home.js'
import About from './pages/about.js'
import AllGames from './pages/allgame.js'
import OkGames from './pages/okgames.js'
import KOGames from './pages/kogames.js'
import ASK from './pages/ask.js'
import AskedGame from './pages/asked.js';
import AddGame from './pages/addgame.js'
import Whisky from './pages/whisky.js'
import CGU from './pages/cgu.js'
import NotFound from './pages/notfound.js'
import Login from'./pages/login.js'
import Cmd from './pages/cmd.js'
import CxPatch from './pages/cxPatch.js'
import Gamedetail from './pages/gamedetail.js'
import News from './pages/news.js'
import Contributor from './pages/contributor.js'
import X from './pages/x.js'

import MyNavbar from './components/Navbar.js'
import Footer from "./components/Footer.js";
import { HashRouter, Routes, Route} from "react-router-dom";



function App() {
  return (
	<HashRouter>
		<MyNavbar />
		<hr/>
		<Routes>
			<Route path='*' element={<NotFound />}/>
			<Route path="/"  element={<Home />} />
			<Route path="/Home" element={<Home />} />
			<Route path="/News" element={<News />} />
			<Route path="/About" element={<About />} />
			<Route path="/AllGames" element={<AllGames />} />
			<Route path="/AskedGames" element={<AskedGame />} />
			<Route path="/OkGames" element={<OkGames />} />
			<Route path="/KOGames" element={<KOGames />} />
			<Route path="/ASK" element={<ASK />} />
			<Route path="/AddGame" element={<AddGame />} />
			<Route path="/Whisky" element={<Whisky />} />
			<Route path="/CGU" element={<CGU />} />
			<Route path="/Cmd" element={<Cmd />} />
			<Route path="/Login" element={<Login />} />
			<Route path="/cxPatch" element={<CxPatch />} />
			<Route path="/gamedetail" element={<Gamedetail />} />
			<Route path="/Contributor" element={<Contributor />} />
			<Route path="/X" element={<X />} />
		</Routes>
		<Footer />
	</HashRouter>
  );
}

export default App;
