import React from 'react';
import Container from 'react-bootstrap/Container';
import { Tweet } from 'react-tweet'
function X() {


	return (
		<Container><center>
			<div data-theme="dark">
				<Tweet id="1840165670161367510" />
				<Tweet id="1839750572263543072" />
			</div></center>
		</Container>
		);
}

export default X;